
import { Component, Prop } from "vue-property-decorator";
import RowMenu from "@/components/reusable/menu/RowMenu";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalGetters } from "@/store/modules/global/types";
/**
 * All menu components use the prop "table" to decide what children components to render.
 *
 * Table === true means the menu is present in the parent table
 * Table === false means the menu is present on the edit screen (usually means hiding certain components, like <Edit>)
 */

@Component
export default class PartMenu extends RowMenu {
  @Prop() id!: number;
  @Prop() item_number!: number;
  @Prop() is_hidden!: boolean;
  @Prop() url!: string;
  @Prop() display_name!: string;
  @Prop({ default: true }) table!: boolean;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  get brand(): string {
    if (this.businessUnit === 'workmaster') {
      return process.env.VUE_APP_WORKMASTER_URL;
    } else {
      return process.env.VUE_APP_C5_URL;
    }
  }

  protected toggleHide(): void {
    this.$emit("toggleHide", this.id, !this.is_hidden);
  }
}
